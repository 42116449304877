/* Frontend-specific app configuration */
//TODO - move into the module shared with the backend

const Conf = {
  POST_LOW_RATING_THRESHOLD: -3,
  COMMENT_LOW_RATING_THRESHOLD: -3,
  SITE_DOMAIN_MIN_LENGTH_CHARS: 3,
  SITE_DOMAIN_MAX_LENGTH_CHARS: 15,
  SITE_NAME_MIN_LENGTH_CHARS: 3,
  SITE_NAME_MAX_LENGTH_CHARS: 20,
}

export default Conf
